<template>
  <div id="allReports">
    <a-page-header
      title="分析报告"
      style="padding-left: 0; margin-bottom: 10px; border-bottom: 1px solid rgb(235, 237, 240)"
    />
    <div class="header">
      <a-form layout="inline">
        <a-form-item label="名称：">
          <a-input v-model="searchForm.reportName" placeholder="请输入" style="width: 200px" />
        </a-form-item>
        <a-form-item label="标签：">
          <a-select
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode || document.body;
              }
            "
            placeholder="请选择"
            option-filter-prop="children"
            style="width: 200px"
            :maxTagCount="1"
            :maxTagTextLength="4"
            :filter-option="filterOption"
            mode="multiple"
            v-model.trim="searchForm.reportLable"
          >
            <a-select-option v-for="(v, i) in labelSelect" :key="i" :value="v.labelId"
              >{{ v.labelName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="分类：">
          <a-select
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode || document.body;
              }
            "
            placeholder="请选择"
            option-filter-prop="children"
            style="width: 200px"
            :maxTagCount="1"
            :maxTagTextLength="4"
            :filter-option="filterOption"
            mode="multiple"
            v-model.trim="searchForm.reportClassify"
          >
            <a-select-option v-for="(v, i) in classTypeList" :key="i" :value="v.id"
              >{{ v.classifyName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <span class="btnClass">
          <a-button type="primary" @click="search" style="margin-top: 3px">
            <a-icon type="search" />
            查询
          </a-button>
          <a-button style="margin-left: 15px" @click="resetSearchForm"> 重置 </a-button>
        </span>
      </a-form>
    </div>
    <a-table
      :columns="columns"
      style="margin-top: 10px"
      :data-source="tableList"
      rowKey="id"
      :pagination="pagination.total > 20 ? pagination : false"
      @change="pageChange"
      bordered
    >
      <template slot="classifyName" slot-scope="text, record">
        <a-tooltip placement="top">
          <template slot="title">
            <span>{{ record.classifyName }}</span>
          </template>
          <div class="overText">
            {{ record.classifyName }}
          </div>
        </a-tooltip>
      </template>
      <span slot="operation" slot-scope="text, record" class="action">
        <a-button @click="openUrl(record)" type="link"> 查看 </a-button>
      </span>
    </a-table>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Base64 from "@/utils/base64";
import { currentProjectAddress } from "@/config";
import moment from "moment";
import { userInfo } from "@/utils/userInfo";
export default {
  data() {
    return {
      // 筛选项
      searchForm: {
        reportName: "",
        reportLable: [],
        reportClassify: [],
      },
      columns: [
        { title: "报告名称", dataIndex: "reportName", key: "reportName" },
        {
          title: "分类",
          dataIndex: "classifyName",
          key: "classifyName",
          width: 300,
          scopedSlots: { customRender: "classifyName" },
        },
        {
          title: "更新时间",
          dataIndex: "gmtModified",
          key: "gmtModified",
          width: 200,
          customRender: (text) => {
            return moment(text).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          width: 100,
          scopedSlots: { customRender: "operation" },
        },
      ],
      //分页数据
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 20,
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
    };
  },
  computed: {
    ...mapState({
      tableList: (state) => state.analysisReport.tableList,
      total: (state) => state.analysisReport.total,
      classTypeList: (state) => state.dashboard.classTypeList,
      labelSelect: (state) => state.dashboard.labelSelect,
    }),
  },
  created() {
    this.getClassSelectList({type:2});
    this.getLabelSelect(); //获取标签下拉值
    this.getData();
  },
  watch: {
    total(value) {
      this.pagination.total = value;
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      getTable: "analysisReport/getTable",
      getSelectList: "dashboard/getSelectList",
      getLabelSelect: "dashboard/getLabelSelect",
      checkLogAddLog: "dashboard/checkLogAddLog",
      getClassSelectList: "dashboard/getClassSelectList",
    }),
    search() {
      this.pagination.pageSize = 20;
      this.pagination.current = 1;
      this.getData();
    },
    resetSearchForm() {
      this.searchForm = this.$options.data.call(this).searchForm;
      this.getData();
    },
    //分页操作
    pageChange(option) {
      this.pagination.current = option.current;
      this.pagination.pageSize = option.pageSize;
      this.getData();
    },
    getData() {
      this.getTable({
        reportName: this.searchForm.reportName,
        reportLable: this.searchForm.reportLable,
        reportClassify: this.searchForm.reportClassify,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        reportType: ["2"],
        reportStatus: "0",
      });
    },
    openUrl(value) {
      this.$sensors.track("PlatformDetail", {
        ...userInfo(),
        product_name: value.reportName,
        ies_product_id: value.id,
        tab_name: "分析报告",
      });
      this.checkLogAddLog({
        module: "分析报告",
        reportName: value.reportName,
      });
      //0 PC  1 H5  2判断当前设备
      // const openUrl = `${currentProjectAddress}templates?openUrl=${value.reportDocumentUrl + "#toolbar=0"}&reportName=${
      //   value.reportDocumentName
      // }&reportType=99`;
      // window.open(openUrl);
      const openUrl = Base64.linkEncode(`${value.reportDocumentUrl}#toolbar=0`, 999, "99");
      window.open(`${currentProjectAddress}templates?openUrl=${openUrl}&reportName=${value.reportDocumentName}`);
    },
    //模糊查询
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
  },
};
</script>
<style scoped>
.overText {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
